import React from 'react';


export const getDisplayCurrency = (number) => {

    return "$" + number.toFixed(2);

}


export const getDisplayPhoneNumber = (number) => {

    if (number.length < 1)
        return "";

    let displayNumber = "(" + number.substring(0,3);

    if (number.length > 3)
        displayNumber += ") " + number.substring(3,6);
    
    if (number.length > 6)
        displayNumber += "-" + number.substring(6,10);

    return displayNumber;

}


export const getDisplayCardNumber = (number) => {

    const cardType = getCardType(number);

    if (number.length < 1)
        return number;
    
    number = number.substring(0,16).replace(/[^\d]+/g, ''); // Limit to 16 digits and numeric

    if (!cardType)
        return number;

    if (cardType === "amex") {

        let displayNumber = number.substring(0,4);

        if (number.length > 4)
            displayNumber += " " + number.substring(4, 10);
        
        if (number.length > 10)
            displayNumber += " " + number.substring(10, 15);
        
        return displayNumber;

    }

    else {

        const chunks = number.match(/.{1,4}/g);
    
        if (!chunks) return "";

        return chunks.join(' ');

    }

}

export const getCardType = (number) => {

    if (!number || number.length < 4)
        return;
    
    if (number.substring(0,2) === "37")
        return "amex";
    
    if (number.substring(0,2) === "38")
        return "diners";
    
    if (number[0] === "4")
        return "visa";
    
    if (number[0] === "5")
        return "mastercard";
    
    if (number[0] === "6")
        return "discover";

}

export const getCardImage = (number) => {

    const cardType = getCardType(number);

    if (!cardType)
        return (<img src={"/images/payment/none.png"} alt="" />);;
    
    return (<img src={"/images/payment/" + cardType + ".png"} alt={cardType} />);

}