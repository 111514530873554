import React from 'react';
import ReactDOM from 'react-dom';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
// import DatePicker from 'react-date-picker';
// import DatePicker from 'react-datepicker';
import formatDate from '../../../utilities/formatDate';
import { Calendar } from 'react-date-range';

export default class PyeCalendarSelector extends React.Component {
    constructor(props) {
        super(props);
       
        this.selectorRef = React.createRef(null);
     }
    state = {
        showSelector: false,
        endDd: 0,
        endMm: 0,
        minDate : new Date(2023, 1, 1),
        maxDate : new Date(2023, 1 + 10 , 0),
        startDate: new Date(),
        dateShow: new Date(2023, 11, 31),
        inputValue: formatDate(new Date(2023, this.props.endMm-1, this.props.endDd),false,false,true)
    }

    componentDidMount = () =>{
        document.addEventListener("mousedown", this.handleClickOutside);
    }
    
    componentWillUnmount = () =>{
        document.removeEventListener("mousedown", this.handleClickOutside);
    }
    
    componentDidUpdate = (next) => {
        if (this.props.endMm !== next.endMm || this.props.endDd !== next.endDd) {
            this.setState({
                inputValue: formatDate(new Date(2023, this.props.endMm-1, this.props.endDd),false,false,true)
            })
        }
    }

    handleClickOutside = (event) => {
        const domNode = ReactDOM.findDOMNode(this);
        if (!domNode || !domNode.contains(event.target)) {
            this.setState({ showSelector: false });
        }


    }
    handleClickInside = (event) => {
        this.setState({ showSelector: !this.state.showSelector })
    }
    render = () => {
        return (<>
            <div className='tpa-plan-editor-calendar'>
                <input 
                        type={"text"}
                        placeholder='MM/DD'
                        value={this.state.inputValue}
                        id="pyeDate"
                        name="pyeDate"
                        onChange={async e => {
                            this.props.onChange(false)

                            this.setState({
                                inputValue: e.target.value
                            })

                            // Check input format
                            const calInput = /[0-1]{1}[0-9]{1}\/[0-31]{1}/;

                            const valid = calInput.test(e.target.value);

                            // Check to see if there's a length of 5 for input
                            if (e.target.value.length === 5 && valid) {
                                let monthVal = String(e.target.value.at(0)+e.target.value.at(1))
                                let dateVal = String(e.target.value.at(3)+e.target.value.at(4))
                                
                                await this.props.updateMm("endMm", parseInt(monthVal))
                                await this.props.updateDd("endDd", parseInt(dateVal))



                                this.props.onChange(true)
                            }

                            
                        }}
                        
                    />
                <div
                    className="plan-editor-plan-date-container"
                    onClick={(e) => {this.state.showSelector === false ? this.handleClickInside(e) : this.handleClickOutside(e);}}
                    ref={this.selectorRef}
                    
                >
                    
                    <div className="config-plan-date-icon" />
                    {this.state.showSelector ? <div
                        className="config-date-selector"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <Calendar
                            showMonthAndYearPickers={true}
                            date={new Date(2023, this.props.endMm-1, this.props.endDd)}
                            months={1}
                            // showMonthArrow = {true}
                            direction="horizontal"
                            editableDateInputs={true}
                            onChange={async(value) => {
                                this.props.onChange(true)

                                await this.props.updateMm("endMm", parseInt(value.toLocaleDateString('en-US', {
                                    month: "numeric"
                                })))

                                await this.props.updateDd("endDd", parseInt(value.toLocaleDateString('en-US', {
                                    day: "numeric"
                                })))

                                this.setState({
                                    dateShow: value, 
                                    showSelector: false 
                                });
                            }}
                        />
                    </div> : ""}
                </div>
            </div>
        </>)
    }
}