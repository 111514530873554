import React from 'react';
import Loading from 'react-loading';
import { toast } from 'react-toastify';
import Markdown from 'react-markdown/with-html';

import api from '../../api';
import Search from '../Search';
import Modal from './Modal';

import '../../styles/templates.css';
import '../../styles/modal.css';

export default class WorkflowTemplate extends Modal {

    state = {
        filter: "",
        loading: true
    }

    componentDidMount = async () => {
        // Load templates
        const res = await api.get('/template/all');

        let templates = [];
        if (res && res.success && res.templates)
            templates = res.templates;

        await this.props.setTemplates(templates);
        await this.setState({ loading: false });

        this.props.loadStacks();
    }

    getTemplateFilter = (t) => (t.name.toLowerCase() + " " + t.description.toLowerCase()).includes(this.state.filter.toLowerCase())

    openAttachModal = async (t) => {       
        this.props.loading(9999, "Creating your workflow...", false)

        let path=""
        if (!this.props.data.root){
            path = this.props.data.name;
        } 
        else {
            path = this.props.data.root+"/"+this.props.data.name
        }
        const res = await api.post('/template/install', {
            root: path,
            templateId: t._id
        });

        if(!res) {
            toast.error("Could not install template. Please contact Stax support for further assistance.")
        }
        this.props.modal();
        this.props.loaded();
        window.location.reload(false);
        this.props.loadStacks();
        return;
    }

    renderTemplate = (t) => (<div
        className="template-card"
        title={t.name}
        onClick={() => this.openAttachModal(t)}
    >
        
        <img src={t.graphic} alt={''}/>
        <div className="template-text">
            <h3>{t.name}</h3>
            <h4>{t.category}</h4>
            <Markdown
                source={t.description}
                escapeHtml={false}
            />
        </div>
    </div>);

    renderContent = () => {
        const featured = this.props.templates.filter(t => t.feature && this.getTemplateFilter(t));
        const featuredIds = featured.map(f => f._id);

        const remaining = this.props.templates.filter(t => !featuredIds.includes(t._id) && this.getTemplateFilter(t));

        return (
            <div className="templates-content">
                <h1><strong>Automate</strong> your document workflow with a preset workflow template</h1>
                <p>
                    Use Stax<span className="stax-dot-small" />ai <strong>templates</strong> to extract data from pre-defined forms, send emails or messages upon receiving documents, send your document or extracted data to <strong>2,000+ other apps</strong>, make API requests, and <i>much more!</i> Templates can be <strong>attached</strong> to your team's stacks such that it is triggered when a document is classified into that stack. Templates can also be <strong>stacked</strong> to have multiple steps in your workflow automation pipeline.
                </p>

                {/* Search for template */}
                <div className="template-input-wrapper">
                    <Search
                        user={this.props.user}
                        query={this.state.filter}
                        search={filter => this.setState({ filter })}
                        className="template-input"
                        placeholder="Search for a template..."
                        style={{ width: 225 }}
                    />
                </div>

                {this.state.loading && <div style={{
                    paddingTop: 40
                }}><Loading
                        type="bubbles"
                        color="var(--color-primary-dark)"
                        height={80}
                        width={80}
                    /></div>}

                {/* Featured Templates */}
                {featured.length > 0 && <div className="template-featured">
                    <h2>Featured Templates</h2>
                    {featured.map((f, idx) => <div
                        key={idx}
                        className="template-card-wrapper"
                        title={f.name}
                        onClick={() => this.openAttachModal(f)}
                    >
                        <img
                            className="template-featured-feature"
                            src={f.feature}
                            alt={f.name}
                        />
                        {this.renderTemplate(f)}
                    </div>)}
                    <div style={{ clear: 'both' }} />
                </div>}

                {/* Remaining templates */}
                {remaining.length > 0 && <>
                    <h2>Automation Templates</h2>
                    <div className="template-remaining">
                    {remaining.map((r, idx) => <div
                        key={idx}
                        className="template-card-wrapper"
                        title={r.name}
                    >{this.renderTemplate(r)}</div>)}
                    </div>
                </>}

                <div style={{ clear: 'both', height: 20 }} />
            </div>
        );
    }
}