import React from 'react';

export default class PlanCard extends React.Component {

    render = () => {
        const p = this.props;
        const plan = p?.plan;
        return (<div
            key={p.idx}
            className={p.selected ? 'plan-editor-card-open' : ''}
            onClick={() => p.onClick?.()}
        >
            <div
                className={(!plan?.internalPlanId || !plan?.directory) ? 'plan-editor-card-lacking' : ''}
            >
                <div
                    className={p.selected ? 'plan-editor-card-selected' : 'plan-editor-card'}
                >
                    <div className='settings-active-format'>
                        {plan?.active ? null : <span className='settings-active-dot' />}
                        <div>
                            {p?.idx < 0 ? <h3 className='plan-editor-header' style={{
                                padding: "0px",
                                margin: "10px",
                                width: "125px",
                                height: "24px",
                                paddingTop: "10px"
                            }}>
                                Your new plan</h3> : <h3 className='plan-editor-card-content'>{plan?.internalPlanId}</h3> }
                        </div>
                    </div>
                    <p className='plan-editor-card-content'>{p?.idx < 0  ? "": plan?.planName}</p>
                </div>
            </div>
        </div>);
    }
}