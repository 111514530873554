import React from 'react';
import Foco from 'react-foco'
import '../../../styles/tableEditor.css';

export default class TableEditor extends React.Component {

    state = {
        mouseOver: {}, // {row, column}
        editCell: {}, // {row, column, value}
        menuOpen: false, // is the context menu open?
        minRowHeight: 0,
        minColumnWidth: 0,
        editHeader: -1,
    }

    componentDidMount = () => {
        this.setState({minRowHeight: 6, minColumnWidth: 6});
    }

    getPageBounds = (pageNumber) => {
        const page = document.getElementById(`page-${pageNumber}`);
        const bounds = {
            left: page?.offsetLeft,
            top: page?.offsetTop,
            width: page?.offsetWidth,
            height: page?.offsetHeight,
        }
        return bounds;
    }

    getCellPosition = (row, cell) => {
        const pageBounds = this.getPageBounds(row.page);

        const left = (cell?.x * pageBounds.width) + pageBounds.left;
        const top = (cell?.y * pageBounds.height) + pageBounds.top;
        const width = cell?.width * pageBounds.width;
        const height = cell?.height * pageBounds.height;

        return {left, top, width, height};
    }

    connectedLeft = (rowIndex, cellIndex) => {
        const rows = this.props.table.rows; 
        const row = rows[rowIndex];
        const orientation = this.getTableRotation(row);
        const pageBounds = this.getPageBounds(row.page);

        if(row.cells.length === 1) return false; 

        return orientation === 'rotated-0' ? 
            cellIndex > 0 && Math.abs(row.cells[cellIndex - 1].x + row.cells[cellIndex - 1].width - row.cells[cellIndex].x) < 3 / pageBounds.width : 
            orientation === 'rotated-90' ? 
            rowIndex < rows.length - 1 && Math.abs(row.x - (rows[rowIndex + 1].x + rows[rowIndex + 1].width)) < 3 / pageBounds.width :
            orientation === 'rotated-180' ? 
            cellIndex < row.cells.length - 1 && Math.abs(row.cells[cellIndex + 1].x + row.cells[cellIndex + 1].width - row.cells[cellIndex].x) < 3 / pageBounds.width :
            orientation === 'rotated-270' ? 
            rowIndex > 0 && Math.abs(row.x - (rows[rowIndex - 1].x + rows[rowIndex - 1].width)) < 3 / pageBounds.width:
            false;

    }
    connectedRight = (rowIndex, cellIndex) => {
        const rows = this.props.table.rows; 
        const row = rows[rowIndex];
        const orientation = this.getTableRotation(row);
        const pageBounds = this.getPageBounds(row.page);
        
        if(row.cells.length === 1) return false; 


        return orientation === 'rotated-0' ? 
            cellIndex < row.cells.length - 1 && Math.abs(row.cells[cellIndex + 1].x - (row.cells[cellIndex].x + row.cells[cellIndex].width)) < 3 / pageBounds.width :
            orientation === 'rotated-90' ? 
            rowIndex > 0 && Math.abs(row.x + row.width - rows[rowIndex - 1].x) < 3 / pageBounds.width :
            orientation === 'rotated-180' ? 
            cellIndex > 0 && Math.abs(row.cells[cellIndex - 1].x - (row.cells[cellIndex].x + row.cells[cellIndex].width)) < 3 / pageBounds.width :
            orientation === 'rotated-270' ? 
            rowIndex < rows.length - 1 && Math.abs(row.x + row.width - rows[rowIndex + 1].x) < 3 / pageBounds.width:
            false;
    }
    connectedTop = (rowIndex, cellIndex) => {
        const rows = this.props.table.rows; 
        const row = rows[rowIndex];
        const cell = row.cells[cellIndex];
        const orientation = this.getTableRotation(row);
        const pageBounds = this.getPageBounds(row.page);

        if(row.cells.length === 1) return false; 


        return orientation === 'rotated-0' ? 
            rowIndex > 0 && Math.abs(rows[rowIndex].y - (rows[rowIndex - 1].y + rows[rowIndex - 1].height)) < 3 / pageBounds.height :
            orientation === 'rotated-90' ? 
            cellIndex > 0 && Math.abs(cell.y - (row.cells[cellIndex - 1].y + row.cells[cellIndex - 1].height)) < 3 / pageBounds.height:
            orientation === 'rotated-180' ? 
            rowIndex < rows.length -1 && Math.abs(row.y - (rows[rowIndex + 1].y + rows[rowIndex + 1].height)) < 3 / pageBounds.height:
            orientation === 'rotated-270' ? 
            cellIndex < row.cells.length - 1 && Math.abs(cell.y - (row.cells[cellIndex + 1].y + row.cells[cellIndex + 1].height)) < 3 / pageBounds.height:
            false; 
    }
    connectedBottom = (rowIndex, cellIndex) => {
        const rows = this.props.table.rows; 
        const row = rows[rowIndex];
        const cell = row.cells[cellIndex];
        const orientation = this.getTableRotation(row);
        const pageBounds = this.getPageBounds(row.page);

        if(row.cells.length === 1) return false; 


        return orientation === 'rotated-0' ? 
            rowIndex < rows.length - 1 && Math.abs(row.y + row.height - rows[rowIndex + 1].y) < 3 / pageBounds.height:
            orientation === 'rotated-90' ? 
            cellIndex < row.cells.length - 1 && Math.abs(cell.y + cell.height - row.cells[cellIndex + 1].y) < 3 / pageBounds.height :
            orientation === 'rotated-180' ? 
            rowIndex > 0 && Math.abs(row.y + row.height - rows[rowIndex - 1].y) < 3 / pageBounds.height:
            orientation === 'rotated-270' ? 
            cellIndex > 0 && Math.abs(cell.y + cell.height - row.cells[cellIndex - 1].y) < 3 / pageBounds.height:
            false;
    }
    
    resizeLeft = async(e, rowIndex, columnIndex) => {
        const table = this.props.table;
        const row = table.rows[rowIndex];
        const pageBounds = this.getPageBounds(row.page);
        const minWidth = 10 / pageBounds.height;
        const orientation = this.getTableRotation(row);
        this.setState({resizing: true});

        let xPos = e.clientX; 

        await new Promise((resolve) => {
            const mouseUp = () => {
                document.removeEventListener('mousemove', mouseMove);
                document.removeEventListener('mouseup', mouseUp);
                this.setState({resizing: false});
                resolve();
            }

            const mouseMove = (e) => {

                let difference = (e.clientX - xPos) / pageBounds.width; 
                const connected = this.connectedLeft(rowIndex, columnIndex); 
                const rows = {...this.props.table.rows};

                // Are we shirking past the min size, or off the page bounds? Stop resizing
                if(
                    orientation === 'rotated-0'? row.cells[columnIndex].width - difference < minWidth || (connected && table.rows[rowIndex].cells[columnIndex - 1].width + difference < minWidth):
                    orientation === 'rotated-90'? table.rows[rowIndex].width - difference < minWidth || (connected && table.rows[rowIndex + 1].width - difference < minWidth):
                    orientation === 'rotated-180'? row.cells[columnIndex].width - difference < minWidth || (connected && table.rows[rowIndex].cells[columnIndex + 1].width + difference < minWidth):
                    orientation === 'rotated-270'? table.rows[rowIndex].width - difference < minWidth || (connected && table.rows[rowIndex - 1].width - difference < minWidth):
                    false
                )
                    mouseUp();

                if(e.clientX < pageBounds.left)
                    mouseUp(); 

                // if ctrl is pressed, resize just that row and column
                if(e.ctrlKey) {
                    if(orientation === 'rotated-0'){
                        if(connected){
                            row.cells[columnIndex - 1].width += difference;
                        }
                        row.x += difference;
                        row.cells[columnIndex].x += difference;
                        row.cells[columnIndex].width -= difference;
                        xPos = e.clientX;
    
                        // was this the first column? If so, resize the row
                        if(columnIndex === 0)
                            row.width -= difference;
                    }
                    else if (orientation === 'rotated-90'){
                        if(connected){
                            rows[rowIndex + 1].width += difference;
                            // Change all the cells 
                            rows[rowIndex + 1].cells.forEach((cell) => {
                                cell.width += difference;
                            }); 
                        }
                        row.x += difference
                        row.width -= difference; 
                        row.cells.forEach((cell) => {
                            cell.x += difference;
                            cell.width -= difference;
                        })
                        xPos = e.clientX;
                    }
                    else if (orientation === 'rotated-180'){
                        if(connected){
                            row.cells[columnIndex + 1].width += difference;
                        }
                        row.x += difference;
                        row.cells[columnIndex].x += difference;
                        row.cells[columnIndex].width -= difference;
                        xPos = e.clientX;
    
                        // was this the first column? If so, resize the row
                        if(columnIndex === 0)
                            row.width -= difference;
                    }
                    else if (orientation === 'rotated-270'){
                        if(connected){
                            rows[rowIndex - 1].width += difference;
                            // Change all the cells 
                            rows[rowIndex - 1].cells.forEach((cell) => {
                                cell.width += difference;
                            }); 
                        }
                        row.x += difference
                        row.width -= difference; 
                        row.cells.forEach((cell) => {
                            cell.x += difference;
                            cell.width -= difference;
                        })
                        xPos = e.clientX;
                    }
                }else {
                    if (orientation === 'rotated-0'){
                            // Resize the row
                        table.rows.map((r) => {
                            if(r.page !== row.page) return r;

                            if(connected){
                                r.cells[columnIndex - 1].width += difference;
                            }
                            r.x += difference;
                            r.cells[columnIndex].x += difference;
                            r.cells[columnIndex].width -= difference;
                            xPos = e.clientX;

                            if(columnIndex === 0)
                                r.width -= difference;
                            
                            return r; 
                        });
                    }
                    if (orientation === 'rotated-90'){
                        if(connected){
                            rows[rowIndex + 1].width += difference;
                            // Change all the cells 
                            rows[rowIndex + 1].cells.forEach((cell) => {
                                cell.width += difference;
                            }); 
                        }
                        row.x += difference
                        row.width -= difference; 
                        row.cells.forEach((cell) => {
                            cell.x += difference;
                            cell.width -= difference;
                        })
                        xPos = e.clientX;
                    }
                    if (orientation === 'rotated-180'){
                        // Resize the row
                        table.rows.map((r) => {
                        if(r.page !== row.page) return r;

                        if(connected){
                            r.cells[columnIndex + 1].width += difference;
                        }
                        r.x += difference;
                        r.cells[columnIndex].x += difference;
                        r.cells[columnIndex].width -= difference;
                        xPos = e.clientX;

                        if(columnIndex === 0)
                            r.width -= difference;
                        
                        return r; 
                    });
                    }
                    if (orientation === 'rotated-270'){
                        if(connected){
                            rows[rowIndex - 1].width += difference;
                            // Change all the cells 
                            rows[rowIndex - 1].cells.forEach((cell) => {
                                cell.width += difference;
                            }); 
                        }
                        row.x += difference
                        row.width -= difference; 
                        row.cells.forEach((cell) => {
                            cell.x += difference;
                            cell.width -= difference;
                        })
                        xPos = e.clientX;
                    }
                }
                this.props.updateTable(this.props.table);
            }

            document.addEventListener('mousemove', mouseMove);
            document.addEventListener('mouseup', mouseUp);
        })

        this.props.saveTableChanges(this.props.table);
    }

    resizeRight = async(e, rowIndex, columnIndex) => {
        const table = this.props.table;
        const row = table.rows[rowIndex];
        const pageBounds = this.getPageBounds(row.page);
        const minWidth = 10 / pageBounds.height;
        const orientation = this.getTableRotation(row);

        let xPos = e.clientX; 

        await new Promise((resolve) => {
            const mouseUp = () => {
                document.removeEventListener('mousemove', mouseMove);
                document.removeEventListener('mouseup', mouseUp);
                this.setState({resizing: false});
                resolve();
            }

            const mouseMove = (e) => {
                let difference = (e.clientX - xPos) / pageBounds.width; 
                const connected = this.connectedRight(rowIndex, columnIndex); 
                const rows = {...this.props.table.rows};

                // Are we shirking past the min size, or off the page bounds? Stop resizing
                if(
                    orientation === 'rotated-0'? row.cells[columnIndex].width - difference < minWidth || (connected && table.rows[rowIndex].cells[columnIndex + 1].width + difference < minWidth):
                    orientation === 'rotated-90'? table.rows[rowIndex].width - difference < minWidth || (connected && table.rows[rowIndex - 1].width - difference < minWidth):
                    orientation === 'rotated-180'? row.cells[columnIndex].width - difference < minWidth || (connected && table.rows[rowIndex].cells[columnIndex - 1].width + difference < minWidth):
                    orientation === 'rotated-270'? table.rows[rowIndex].width - difference < minWidth || (connected && table.rows[rowIndex + 1].width - difference < minWidth):
                    false
                )
                    mouseUp();

                if(e.clientX > pageBounds.left + pageBounds.width)
                    mouseUp(); 

                // if ctrl is pressed, resize just that row and column
                if(e.ctrlKey) {
                    if (orientation === 'rotated-0'){
                        if(connected){
                            row.cells[columnIndex + 1].width -= difference;
                            row.cells[columnIndex + 1].x += difference;
                        }
                        row.cells[columnIndex].width += difference;
    
                        // was this the last column? If so, resize the row
                        if(columnIndex === row.cells.length - 1)
                            row.width += difference;
    
                        xPos = e.clientX;
                    }
                    if (orientation === 'rotated-90'){
                        if(connected){
                            rows[rowIndex - 1].width -= difference;
                            rows[rowIndex - 1].x += difference;
                            rows[rowIndex - 1].cells.forEach((cell) => {
                                cell.width -= difference;
                                cell.x += difference;
                            });
                        }
                        row.width += difference; 
                        row.cells.forEach(cell => {
                            cell.width += difference;
                        })

                        xPos = e.clientX;
                    }
                    if (orientation === 'rotated-180'){
                        if(connected){
                            row.cells[columnIndex - 1].width -= difference;
                            row.cells[columnIndex - 1].x += difference;
                        }
                        row.cells[columnIndex].width += difference;
    
                        // was this the last column? If so, resize the row
                        if(columnIndex === row.cells.length - 1)
                            row.width += difference;
    
                        xPos = e.clientX;
                    }
                    if (orientation === 'rotated-270'){
                        if(connected){
                            rows[rowIndex + 1].width -= difference;
                            rows[rowIndex + 1].x += difference;
                            rows[rowIndex + 1].cells.forEach((cell) => {
                                cell.width -= difference;
                                cell.x += difference;
                            });
                        }
                        row.width += difference; 
                        row.cells.forEach(cell => {
                            cell.width += difference;
                        })

                        xPos = e.clientX;
                    }
                }else {
                    if (orientation === 'rotated-0'){
                        // Resize the row
                        table.rows.map((r) => {
                            if(r.page !== row.page) return r;

                            if(connected){
                                r.cells[columnIndex + 1].width -= difference;
                                r.cells[columnIndex + 1].x += difference;
                            }
                            r.cells[columnIndex].width += difference;

                            // was this the last column? If so, resize the row
                            if(columnIndex === row.cells.length - 1)
                                r.width += difference;

                            xPos = e.clientX;


                            return r;
                        });
                    }
                    if (orientation === 'rotated-90'){
                        if(connected){
                            rows[rowIndex - 1].width -= difference;
                            rows[rowIndex - 1].x += difference;
                            rows[rowIndex - 1].cells.forEach((cell) => {
                                cell.width -= difference;
                                cell.x += difference;
                            });
                        }
                        row.width += difference; 
                        row.cells.forEach(cell => {
                            cell.width += difference;
                        })

                        xPos = e.clientX;
                    }
                    if (orientation === 'rotated-180'){
                        // Resize the row
                        table.rows.map((r) => {
                            if(r.page !== row.page) return r;

                            if(connected){
                                r.cells[columnIndex - 1].width -= difference;
                                r.cells[columnIndex - 1].x += difference;
                            }
                            r.cells[columnIndex].width += difference;

                            // was this the last column? If so, resize the row
                            if(columnIndex === row.cells.length - 1)
                                r.width += difference;

                            xPos = e.clientX;


                            return r;
                        });
                    }
                    if (orientation === 'rotated-270'){
                        if(connected){
                            rows[rowIndex + 1].width -= difference;
                            rows[rowIndex + 1].x += difference;
                            rows[rowIndex + 1].cells.forEach((cell) => {
                                cell.width -= difference;
                                cell.x += difference;
                            });
                        }
                        row.width += difference; 
                        row.cells.forEach(cell => {
                            cell.width += difference;
                        })

                        xPos = e.clientX;
                    }
                    
                }

                this.props.updateTable(this.props.table);
            }

            document.addEventListener('mousemove', mouseMove);
            document.addEventListener('mouseup', mouseUp);
        })

        this.props.saveTableChanges(this.props.table);
    }

    resizeTop = async(e, rowIndex, columnIndex) => {
        const table = this.props.table;
        const row = table.rows[rowIndex];
        const pageBounds = this.getPageBounds(row.page);
        const minWidth = 10 / pageBounds.height;
        const orientation = this.getTableRotation(row);
        let yPos = e.clientY;

        await new Promise((resolve) => {
            const mouseUp = () => {
                document.removeEventListener('mousemove', mouseMove);
                document.removeEventListener('mouseup', mouseUp);
                this.setState({resizing: false});
                resolve();
            }

            const mouseMove = (e) => {
                let difference = (e.clientY - yPos) / pageBounds.height; 
                const connected = this.connectedTop(rowIndex, columnIndex);

                // Are we shirking past the min size, or off the page bounds? Stop resizing
                if(
                    orientation === 'rotated-0'? row.height - difference < minWidth || (connected && table.rows[rowIndex - 1].height + difference < minWidth):
                    orientation === 'rotated-90'? row.cells[columnIndex].height - difference < minWidth || (connected && row.cells[columnIndex - 1].height - difference < minWidth):
                    orientation === 'rotated-180'? row.height - difference < minWidth || (connected && table.rows[rowIndex + 1].height + difference < minWidth):
                    orientation === 'rotated-270'? row.cells[columnIndex].height - difference < minWidth || (connected && row.cells[columnIndex + 1].height - difference < minWidth):
                    false
                )
                    mouseUp();

                if(e.clientY < pageBounds.top)
                    mouseUp(); 

                if(orientation === 'rotated-0'){
                    row.y += difference;
                    row.height -= difference; 
                    row.cells.forEach((cell) => {
                        cell.y = row.y;
                        cell.height = row.height;
                    })
    
                    if(connected){
                        table.rows[rowIndex - 1].height += difference;
                        table.rows[rowIndex - 1].cells.forEach((cell) => {
                            cell.height += difference;
                        })
                    }
                    this.props.updateTable(table);
                }
                if(orientation === 'rotated-90'){
                    if(e.ctrlKey){
                        // Only one row...
                        if(this.connectedTop(rowIndex, columnIndex)){
                            row.cells[columnIndex - 1].height += difference; 
                        }
                        row.cells[columnIndex].y += difference;
                        row.cells[columnIndex].height -= difference;
                    }else{
                        // All table rows on the page
                        table.rows.forEach((r, rIdx) => {
                            if(r.page !== row.page) return r;
                            if(this.connectedTop(rIdx, columnIndex)){
                                r.cells[columnIndex - 1].height += difference; 
                            }
                            r.cells[columnIndex].y += difference;
                            r.cells[columnIndex].height -= difference;
                        });
                    }
                }
                if(orientation === 'rotated-180'){
                    row.y += difference;
                    row.height -= difference; 
                    row.cells.forEach((cell) => {
                        cell.y = row.y;
                        cell.height = row.height;
                    })
    
                    if(connected){
                        table.rows[rowIndex + 1].height += difference;
                        table.rows[rowIndex + 1].cells.forEach((cell) => {
                            cell.height += difference;
                        })
                    }
                    this.props.updateTable(table);
                }
                if(orientation === 'rotated-270'){
                    if(e.ctrlKey){
                        // Only one row...
                        if(this.connectedTop(rowIndex, columnIndex)){
                            row.cells[columnIndex + 1].height += difference; 
                        }
                        row.cells[columnIndex].y += difference;
                        row.cells[columnIndex].height -= difference;
                    }else{
                        // All table rows on the page
                        table.rows.forEach((r, rIdx) => {
                            if(r.page !== row.page) return r;
                            if(this.connectedTop(rIdx, columnIndex)){
                                r.cells[columnIndex + 1].height += difference; 
                            }
                            r.cells[columnIndex].y += difference;
                            r.cells[columnIndex].height -= difference;
                        });
                    }
                }
                yPos = e.clientY;
            }
            document.addEventListener('mousemove', mouseMove);
            document.addEventListener('mouseup', mouseUp);
        })
        this.props.saveTableChanges(this.props.table);
    }

    resizeBottom = async(e, rowIndex, columnIndex) => {
        const table = this.props.table;
        const row = table.rows[rowIndex];
        const pageBounds = this.getPageBounds(row.page);
        const minWidth = 10 / pageBounds.height;
        const orientation = this.getTableRotation(row);

        let yPos = e.clientY;

        await new Promise((resolve) => {
            const mouseUp = () => {
                document.removeEventListener('mousemove', mouseMove);
                document.removeEventListener('mouseup', mouseUp);
                this.setState({resizing: false});
                resolve();
            }

            const mouseMove = (e) => {
                let difference = (e.clientY - yPos) / pageBounds.height; 
                const connected = this.connectedBottom(rowIndex, columnIndex);

                // Are we shirking past the min size, or off the page bounds? Stop resizing
                if(
                    orientation === 'rotated-0'? row.height + difference < minWidth || (connected && table.rows[rowIndex + 1].height + difference < minWidth):
                    orientation === 'rotated-90'? row.cells[columnIndex].height + difference < minWidth || (connected && row.cells[columnIndex + 1].height + difference < minWidth):
                    orientation === 'rotated-180'? row.height + difference < minWidth || (connected && table.rows[rowIndex - 1].height + difference < minWidth):
                    orientation === 'rotated-270'? row.cells[columnIndex].height + difference < minWidth || (connected && row.cells[columnIndex + 1].height + difference < minWidth):
                    false
                )
                    mouseUp();

                if(e.clientY > pageBounds.top + pageBounds.height)
                    mouseUp();

                // if(row.y + difference <= 0){
                //     mouseUp();  
                // }  

                if(orientation === 'rotated-0'){
                    row.height += difference; 
                    row.cells.forEach((cell) => {
                        cell.height = row.height;
                    })
    
                    if(connected){
                        table.rows[rowIndex + 1].y += difference;
                        table.rows[rowIndex + 1].height -= difference;
                        table.rows[rowIndex + 1].cells.forEach((cell) => {
                            cell.height = table.rows[rowIndex + 1].height;
                            cell.y = table.rows[rowIndex + 1].y;
                        })
                    }
                    this.props.updateTable(table);
                }
                if(orientation === 'rotated-90'){
                    if(e.ctrlKey){
                        // Only one row...
                        if(this.connectedBottom(rowIndex, columnIndex)){
                            row.cells[columnIndex + 1].y += difference;
                            row.cells[columnIndex + 1].height -= difference; 
                        }
                        row.cells[columnIndex].height += difference;
                    }else{
                        // All table rows on the page
                        table.rows.forEach((r, rIdx) => {
                            if(r.page !== row.page) return r;
                            if(this.connectedBottom(rowIndex, columnIndex)){
                                r.cells[columnIndex + 1].y += difference;
                                r.cells[columnIndex + 1].height -= difference; 
                            }
                            r.cells[columnIndex].height += difference;
                        });
                    }            
                }
                if(orientation === 'rotated-180'){
                    row.height += difference; 
                    row.cells.forEach((cell) => {
                        cell.height = row.height;
                    })
    
                    if(connected){
                        table.rows[rowIndex - 1].y += difference;
                        table.rows[rowIndex - 1].height -= difference;
                        table.rows[rowIndex - 1].cells.forEach((cell) => {
                            cell.height = table.rows[rowIndex - 1].height;
                            cell.y = table.rows[rowIndex - 1].y;
                        })
                    }
                    this.props.updateTable(table);
                }
                if(orientation === 'rotated-270'){
                    if(e.ctrlKey){
                        // Only one row...
                        if(this.connectedBottom(rowIndex, columnIndex)){
                            row.cells[columnIndex - 1].y += difference;
                            row.cells[columnIndex - 1].height -= difference; 
                        }
                        row.cells[columnIndex].height += difference;
                    }else{
                        // All table rows on the page
                        table.rows.forEach((r, rIdx) => {
                            if(r.page !== row.page) return r;
                            if(this.connectedBottom(rowIndex, columnIndex)){
                                r.cells[columnIndex - 1].y += difference;
                                r.cells[columnIndex - 1].height -= difference; 
                            }
                            r.cells[columnIndex].height += difference;
                        });
                    }            
                }
                yPos = e.clientY;
            }
            document.addEventListener('mousemove', mouseMove);
            document.addEventListener('mouseup', mouseUp);
        })
        this.props.saveTableChanges(this.props.table);
    }


    freeMoveRow = async(e, rowIndex, columnIndex) => {
        const table = this.props.table; 
        const row = table.rows[rowIndex];
        const pageBounds = this.getPageBounds(row.page);
        this.setState({resizing: true});

        let yPos = e.clientY;
        let xPos = e.clientX;

        await new Promise((resolve) => {
            const mouseUp = () => {
                document.removeEventListener('mousemove', mouseMove);
                document.removeEventListener('mouseup', mouseUp);
                this.setState({resizing: false});
                resolve();
            }

            const mouseMove = (e) => {
                let yDifference = (e.clientY - yPos) / pageBounds.height;
                let xDifference = (e.clientX - xPos) / pageBounds.width;

                // Move the row 
                row.y += yDifference;
                row.x += xDifference;
                row.cells.forEach((cell) => {
                    cell.y += yDifference;
                    cell.x += xDifference;
                })

                this.props.updateTable(table);
                xPos = e.clientX;
                yPos = e.clientY;
            }
            document.addEventListener('mousemove', mouseMove);
            document.addEventListener('mouseup', mouseUp);
        })

        this.props.saveTableChanges(this.props.table);
    }

    quickAddRowStyle = () => {
        const table = this.props?.table; 

        if(!table) return {display: 'none'};
        
        //find the lowest row in the table 
        const lowestRow = table.rows.reduce((acc, row) => {
            if(row.y > acc.y || row.page > acc.page){
                return row;
            }else{
                return acc;
            }
        }, {y: 0})

        if(lowestRow.y + lowestRow.height + (24 / this.getPageBounds(lowestRow.page).height) >= 1) 
            return {display: 'none'};

        // set the style to be the bottom of the lowest row
        const style = {
            top: (lowestRow.y * this.getPageBounds(lowestRow.page).height  + lowestRow.height * this.getPageBounds(lowestRow.page).height) + 15 + this.getPageBounds(lowestRow.page).top,
            left: lowestRow.x * this.getPageBounds(lowestRow.page).width + (lowestRow.width * this.getPageBounds(lowestRow.page).width) / 2 + this.getPageBounds(lowestRow.page).left,
            zIndex: 1000
        }

        // is top > 100% of page height?
        return style.top > this.getPageBounds().height ? {display: 'none'} : style;
    }


    handleKeyDown = (e) => {
           // Check for enter or escape
           if(e.key === 'Enter' || e.key === 'Escape'){
            // Update the table
            const table = {...this.props.table};
            if(this.state.editHeader >= 0){
                table.headers[this.state.editHeader] = e.target.value;
            }else{
                table.rows[this.state.editCell.row].cells[this.state.editCell.column].text = e.target.value;
                table.rows[this.state.editCell.row].cells[this.state.editCell.column].confidence = this.state.editCell.confidence?? 100;
            }

            // Update the state
            this.setState({
                editCell: {},
                editHeader: -1, 
            })
            this.props.updateTable(table);
            this.props.saveTableChanges(this.props.table);
        }
    }

    getTableRotation = (row) => {
        if(!row || !row.cells || !row.cells.length > 1) return `rotated-${this.props.document.rotations?.[row.page]?? 0}`;
        
        const {left: leftOne, top: topOne, width: widthOne, height: heightOne} = 
            this.getCellPosition(row, row.cells[0]);
        const {left: leftTwo, top: topTwo} =
            this.getCellPosition(row, row.cells[row.cells.length - 1]); 

        let orientation = ''; 
        if(leftTwo > (leftOne + (widthOne * .8)))
            orientation = 'rotated-0';
        else if (leftTwo < (leftOne - (widthOne * .8)))
            orientation = 'rotated-180';
        else if (topTwo > (topOne + (heightOne * .8)))
            orientation = 'rotated-90';
        else if (topTwo < (topOne - (heightOne * .8)))
            orientation = 'rotated-270';
        else return `rotated-${this.props.document.rotations?.[row.page]?? 0}`
        return orientation;
    }

    rotateAndRenderText = (row, text) => {
        const orientation = this.getTableRotation(row); 
        return (
            <div
                style={{
                    color: 'inherit',
                    transform: `rotate(${orientation.replace('rotated-', '')}deg)`,
                }}
            >{text}</div>   
        )
    }

    renderRowHeader = (rowIndex) => {
        // What is the orientation of the table, compare the first and last column of the row...
        // col 0 to the left of col 1? - standard 
        // col 0 to the right of col 1? - rotated 180
        // col 0 above col 1? - rotated 90
        // col 0 below col 1? - rotated 270
        const rows = this.props.table.rows; 
        const row = rows[rowIndex];

        const orientation = this.getTableRotation(row); 
        const connectedTop =    
            orientation === 'rotated-0'? this.connectedTop(rowIndex, 0):
            orientation === 'rotated-90'? this.connectedRight(rowIndex, 0):
            orientation === 'rotated-180'? this.connectedBottom(rowIndex, 0):
            orientation === 'rotated-270'? this.connectedLeft(rowIndex, 0):
            false;

        const connectedBottom = 
            orientation === 'rotated-0'? this.connectedBottom(rowIndex, 0):
            orientation === 'rotated-90'? this.connectedLeft(rowIndex, 0):
            orientation === 'rotated-180'? this.connectedTop(rowIndex, 0):
            orientation === 'rotated-270'? this.connectedRight(rowIndex, 0):
            false;
        // Build the bounds depending on the orientation of the table... 
        // standard... to the left of row[0]
        // rotated-180... to the right of row[0]
        // rotated-90... above row[0]
        // rotated-270... below row[0]
        let bounds = {};
        let {top, left, width, height} = this.getCellPosition(row, row.cells[0]); 
        switch (orientation) {
            case 'rotated-0':
              bounds = {
                left: left-16,
                top: top,
                width: 16,
                height: height,
                borderRadius: `${connectedTop? '0': '6px'} 0 0 ${connectedBottom? '0': '6px'}`,
                highlightRadius: '6px 0 0 6px',
              };
              break;
            case 'rotated-180':
                bounds = {
                    left: left + width,
                    top: top,
                    width: 16,
                    height: height,
                    borderRadius: `0 ${connectedBottom? '0': '6px'} ${connectedTop? '0': '6px'} 0`,
                    highlightRadius: '0 6px 6px 0',
                  };
              break;                                        
            case 'rotated-90':
              bounds = {
                left: left,
                top: top - 16,
                width: width,
                height: 16,
                borderRadius: `${connectedBottom? '0': '6px'} ${connectedTop? '0': '6px'} 0 0`,
                highlightRadius: '6px 6px 0 0',
              };
              break;
            case 'rotated-270':
              bounds = {

                left: left,
                top: top + height,
                width: width,
                height: 16,
                borderRadius: `0 0 ${connectedBottom? '0': '6px'} ${connectedTop? '0': '6px'}`,
                highlightRadius: '0 0 6px 6px',
              };
              break;
            default:
              // Handle invalid orientation if needed
              break;
          }

        return (
            <div
                className='table-editor-header'
                style={{
                    ...bounds,
                }}
            >
                {
                    this.state.mouseOver.row === rowIndex && 
                    <div 
                        style={{
                            ...bounds, 
                            borderRadius: bounds.highlightRadius,
                            backgroundColor: 'var(--color-accent)',
                            position: 'absolute',
                            left: 0,
                            top: 0
                        }}
                    />
                }
                {this.rotateAndRenderText(row, rowIndex + 1)}
                </div>
        )
    }

    renderColumnHeaders = (rowIndex) => {
        const row = this.props.table.rows[rowIndex];
        const orientation = this.getTableRotation(row); 
        const HEADERS = 'ABCDEFGHIJKLMNOPQRSTUVWZYX';
        return (
            <>
                {
                    row.cells.map((cell, idx) => {

                        const connectedLeft =    
                            orientation === 'rotated-0'? this.connectedLeft(rowIndex, idx):
                            orientation === 'rotated-90'? this.connectedTop(rowIndex, idx):
                            orientation === 'rotated-180'? this.connectedRight(rowIndex, idx):
                            orientation === 'rotated-270'? this.connectedBottom(rowIndex, idx):
                            false;
                
                        const connectedRight = 
                            orientation === 'rotated-0'? this.connectedRight(rowIndex, idx):
                            orientation === 'rotated-90'? this.connectedBottom(rowIndex, idx):
                            orientation === 'rotated-180'? this.connectedLeft(rowIndex, idx):
                            orientation === 'rotated-270'? this.connectedTop(rowIndex, idx):
                            false;
                        
                        let bounds = {};
                        let inputBounds = {}; 
                        let {top, left, width, height} = this.getCellPosition(row, cell); 
                        switch (orientation) {
                            case 'rotated-0':
                                bounds = {
                                left: left,
                                top: top-16,
                                width: width,
                                height: 16,
                                borderRadius: `${connectedLeft? '0': '6px'} ${connectedRight? '0': '6px'} 0 0`,
                                highlightRadius: '6px 6px 0 0',
                                };
                                inputBounds = {...bounds}
                                break;
                            case 'rotated-180':
                                bounds = {
                                left: left,
                                top: top + height,
                                width: width,
                                height: 16,
                                borderRadius: `0 0 ${connectedLeft? '0': '6px'} ${connectedRight? '0': '6px'}`,
                                highlightRadius: '0 0 6px 6px',
                                };
                                inputBounds = {
                                    ...bounds,
                                    left: left + width, 
                                    top: top + height + 16, 
                                }
                                break;
                            case 'rotated-90':
                                bounds = {
                                    left: left + width,
                                    top: top,
                                    width: 16,
                                    height: height,
                                    borderRadius: `0 ${connectedLeft? '0': '6px'} ${connectedRight? '0': '6px'} 0`,
                                    highlightRadius: '0 6px 6px 0',
                                };
                                inputBounds = {
                                    left: left + width + 16,
                                    top: top,
                                    width: height,
                                    height: 16,
                                }
                                break;
                            case 'rotated-270':
                                bounds = {

                                left: left - 16,
                                top: top,
                                width: 16,
                                height: height,
                                borderRadius: `${connectedRight? '0': '6px'} 0 0 ${connectedLeft? '0': '6px'}`,
                                highlightRadius: '6px 0 0 6px',
                                };
                                inputBounds = {
                                    left: left -16,
                                    top: top + height,
                                    width: height,
                                    height: 16,
                                }
                                break;
                            default:
                                // Handle invalid orientation if needed
                                break;
                            }

                        return (
                            <>
                            {this.state.editHeader === idx?
                                <input 
                                    style={{
                                        position: 'absolute',
                                        transform: `rotate(${orientation.replace("rotated-", '')}deg)`,
                                        transformOrigin: 'top left',
                                        ...inputBounds,
                                    }}
                                    className='table-editor-cell-input'
                                    type='text'
                                    key={`table-editor-column-header-input-${idx}`}
                                    value={this.props.table?.headers[idx]??""}
                                    autoFocus="autofocus"
                                    onKeyDown={this.handleKeyDown}
                                    onChange={(e) => {
                                        const table = {...this.props.table};
                                        table.headers[idx] = e.target.value;
                                        this.props.updateTable(table)
                                    }}
                                />
                                :    
                                <div
                                className='table-editor-header column-header'
                                title={this.props.table.headers[idx]?.length?this.props.table.headers[idx] :HEADERS[idx]}
                                style={{
                                    ...bounds,
                                }}
                                onClick={() => {
                                    this.setState({editHeader: idx})
                                }}
                            >
                                {
                                    this.state.mouseOver.column === idx && 
                                    <div 
                                        style={{
                                            ...bounds, 
                                            borderRadius: bounds.highlightRadius,
                                            backgroundColor: 'var(--color-accent)',
                                            position: 'absolute',
                                            left: 0,
                                            top: 0
                                        }}

                                    />
                                }
                            {this.rotateAndRenderText(row, this.props.table.headers[idx]?.length?this.props.table.headers[idx] :HEADERS[idx])}
                            </div>
                            }
                            </>
                           
                        )
                    })

                }
            </>
        )
    }


    render = () => {
        if(!this.props.table.rows || !this.props.table.rows.length)
            return <></>
        // Find the first row of each page to render the column headers 
        const firstRows = this.props.table.rows.reduce((acc, row, index) => {
            if(acc.find((r) => r.page === row.page)) return acc;
            return [...acc, {...row, index}];
        }
        , []);

        return (
            <div className='tableContainer'>
                {firstRows.map((row) => {
                    return this.renderColumnHeaders(row.index);
                })}
                {/* {this.renderRowHeaders()} */}
                {/* Map out each row and column in the table */}
                {this.props.table.rows.map((row, rowIndex) => {
                    return (
                        <React.Fragment key={rowIndex}>
                            {this.renderRowHeader(rowIndex)}
                            {row.cells.map((cell, columnIndex) => {

                                // const connectedLeft = this.connectedLeft(rowIndex, columnIndex);
                                // const connectedRight = this.connectedRight(rowIndex, columnIndex);
                                // const connectedTop = this.connectedTop(rowIndex, columnIndex);
                                // const connectedBottom = this.connectedBottom(rowIndex, columnIndex);

                                const editing = this.state.editCell.row === rowIndex && this.state.editCell.column === columnIndex;
                                const mouseOverRow = this.state.mouseOver.row === rowIndex;
                                const mouseOverCol = this.state.mouseOver.column === columnIndex;
                                const contextMenuCell = this.props.contextMenuCell.column === columnIndex && this.props.contextMenuCell.row === rowIndex;

                                const cellBounds = this.getCellPosition(row, cell); 
                                const inputBounds = {}; 
                                const tableRotation = this.getTableRotation(row);
                                switch (tableRotation) {
                                    case 'rotated-0':
                                        inputBounds.width = cellBounds.width;
                                        inputBounds.height = cellBounds.height; 
                                        break;
                                    case 'rotated-180':
                                        inputBounds.width = cellBounds.width;
                                        inputBounds.height = cellBounds.height; 
                                        inputBounds.top = cellBounds.height;
                                        inputBounds.left = cellBounds.width;
                                        break;
                                    case 'rotated-90':
                                        inputBounds.width = cellBounds.height;
                                        inputBounds.height = cellBounds.width; 
                                        inputBounds.left =  cellBounds.width
                                        break;
                                    case 'rotated-270':
                                        inputBounds.width = cellBounds.height;
                                        inputBounds.height = cellBounds.width; 
                                        inputBounds.top =  cellBounds.height;
                                        break;
                                    default:
                                        break;
                                }

                                return (<React.Fragment key={columnIndex}>
                                    {editing?
                                        <Foco
                                            onClickOutside={()=>{

                                                // Update the table
                                                const table = {...this.props.table};
                                                table.rows[this.state.editCell.row].cells[this.state.editCell.column].text = this.state.editCell.value;

                                                // Update the state
                                                this.setState({
                                                    editCell: {},
                                                })
                                                this.props.updateTable(table);
                                                this.props.saveTableChanges(this.props.table);
                                            }}
                                        >
                                            <div
                                                style={{...this.getCellPosition(row, cell), position: 'absolute'}}
                                            >
                                                <input
                                                className='table-editor-cell-input'
                                                type='text'
                                                key={`table-editor-cell-input-${rowIndex}-${columnIndex}`}
                                                value={this.state.editCell.value}
                                                autoFocus="autofocus"
                                                onKeyDown={this.handleKeyDown}
                                                onChange={(e) => {
                                                    this.setState({
                                                        editCell: {
                                                            ...this.state.editCell,
                                                            value: e.target.value,
                                                            confidence: 100
                                                        }
                                                    })
                                                }}
                                                style={{
                                                    position: 'absolute',
                                                    transform: `rotate(${tableRotation.replace("rotated-", '')}deg)`,
                                                    transformOrigin: 'top left',
                                                    ...inputBounds,
                                                }}
                                                onContextMenu={(e) => {
                                                    this.props.setContextMenuCell({row: rowIndex, column: columnIndex});
                                                    this.props.onContextMenu(e);
                                                    e.preventDefault();
                                                }}
                                            />
                                            </div>
                                          
                                        </Foco> 
                                        :
                                        <div
                                            className={
                                                `table-editor-cell
                                                ${mouseOverRow? " table-editor-cell-hover-row" : ""}
                                                ${mouseOverCol && mouseOverRow? " table-editor-cell-hover" : ""}
                                                ${contextMenuCell? " table-editor-cell-hover" : ""}
                                                ${cell.confidence < 0.95? " table-editor-cell-medium-high-confidence" : ""}
                                                ${cell.confidence < 0.80? " table-editor-cell-medium-confidence" : ""} 
                                                ${cell.confidence < 0.60? " table-editor-cell-low-confidence" : ""}
                                                `}
                                            id={`table-editor-${rowIndex}-${columnIndex}`}
                                            style={this.getCellPosition(row, cell)}
                                            onMouseOver={() => {
                                                this.setState({mouseOver: {row: rowIndex, column: columnIndex}});
                                            }}
                                            onMouseOut={() => {
                                                this.setState({mouseOver: {}});
                                            }}
                                            onMouseUp={(e) => {
                                                if(!this.state.resizing && Object.keys(this.props.contextMenuCell).length === 0)
                                                    this.setState({editCell: {row: rowIndex, column: columnIndex, value: cell.text}});
                                            }}
                                            onMouseDown={(e) => {
                                                // if ctrl is not pressed, do nothing 
                                                if(!e.ctrlKey || !e.shiftKey) return;
                                                this.freeMoveRow(e, rowIndex, columnIndex);
                                            }}

                                            onContextMenu={(e) => {
                                                this.props.setContextMenuCell({row: rowIndex, column: columnIndex});
                                                this.props.onContextMenu(e);

                                                // listen for a mouse click outside of the context menu
                                                document.addEventListener('click', (e) => {
                                                    if(e.target.className !== 'context-menu-item'){
                                                        this.props.setContextMenuCell({});
                                                    }
                                                }, {once: true});
                                            }}

                                        >
                                            {mouseOverRow && mouseOverCol? this.rotateAndRenderText(row, cell.text): ""}
                
                                            <div className='resize-handle-left' onMouseDown={(e) => this.resizeLeft(e, rowIndex, columnIndex)}/>
                                            <div className='resize-handle-right' onMouseDown={(e) => this.resizeRight(e, rowIndex, columnIndex)}/>
                                            <div className='resize-handle-top' onMouseDown={(e) => this.resizeTop(e, rowIndex, columnIndex)}/>
                                            <div className='resize-handle-bottom' onMouseDown={(e) => this.resizeBottom(e, rowIndex, columnIndex)}/>                                    
                                        </div>
                                        
                                    }</React.Fragment>
                                )
                            })}
                        </React.Fragment>
                    )
                })}

                    {this.props.table?.rows?.length > 0 && this.getTableRotation(this.props?.table?.rows[this.props?.table?.rows?.length -1]) === 'rotated-0'? 
                    <div
                        className="table-editor-new-row-button"
                        title="Add row below"
                        style={this.quickAddRowStyle()}
                        onClick={() => {
                            // Add a full new row (dont split it) as the last row in the table 
                            const table = this.props.table; 
                            // sort the rows by y position
                            
                            let newRowHeight = 0;
                            // will adding a new row on top go above the page bounds? adjust the height of the new row to fit 
                            if(table.rows[table.rows.length - 1].y + (table.rows[table.rows.length - 1].height * 2) > 1){
                                newRowHeight = 1 - (table.rows[table.rows.length - 1].y + table.rows[table.rows.length - 1].height);
                            }else newRowHeight = table.rows[table.rows.length - 1].height;
                            
                            if(newRowHeight <= 0) return;
                    
                            const lastRow = table.rows[table.rows.length - 1];
                            table.rows.push({
                                x: lastRow.x,
                                y: lastRow.y + lastRow.height,
                                width: lastRow.width,
                                height: newRowHeight,
                                page: lastRow.page,
                                cells: new Array(lastRow.cells.length).fill(0).map((_, idx) => {
                                    return {
                                        x: lastRow.cells[idx].x,
                                        y: lastRow.y + lastRow.height,
                                        width: lastRow.cells[idx].width,
                                        height: newRowHeight,
                                        text: '',
                                    }
                                })
                            })
                    
                            // Update the state and save the changes
                            this.setState({table});
                            this.props.saveTableChanges(table);

                        }}
                    >
                        Add a new row
                    </div>
                    :
                    ""
                }



            </div>
            
        )
       
    }
}

