const reports = {
    "5500_report": "5500 Reports",
    distribution: "Distribution Reports",
    forfeiture: "Forfeiture Reports",
    loan_audit: "Loan Audit Reports",
    plan_admin: "Plan Administration Reports",
    balance: "Account Balance Reports",
    compliance: "Compliance Reports",
    statement: "Account Statements",
    deposit: "Deposit Reports",
    contribution: "Contribution Reports",
    loan_detail: "Loan Detail Reports",
    employer_statement: "Employer Statements",
    schedule_a: "Schedule A Reports",
    schedule_c: "Schedule C Reports",
    tpa_eDownload: "TPA E-Download Year End Reports",
    outstanding_loan: "Outstanding Loan Reports",
    loan_repayments: "Loan Repayment Reports",
    data_extract: "Loan Data Extract Reports",
    loan_maintenance: "Loan Maintenance Reports",
    partic_payroll_detail: "Participant Payroll Detail Reports",
    cash_account: "Cash Account Reports",
    investment_allocation: "Investment Allocation Reports",
    tpa_download: "TPA Download: Pre-Schedule Reports",
    excess_deferral_report: "Excess Deferral Report", 
    separation_detail_report: "Separation Detail Report", 
    vesting_status_detail_report: "Vesting Status Detail Report", 
    plan_forfeiture_report: "Plan Forfeiture Report", 
    contribution_report: "Contribution Report", 
    daily_distribution_report: "Daily Distribution Report", 
    outstanding_distribution_checks: "Outstanding Distribution Checks",
    loan_amortization_schedule: "Loan Amortization Schedule",
    daily_plan_level_transaction_report: "Daily Plan Level Transaction Report",
    payroll_change_notification_report: "Payroll Change Notification Report",
    trust_report: "Trust Report"
};

export const getReportsFor = (keys) => {
    let folders = {};

    // Trust Report for RK TA
    folders['trust_report_excel_file'] = "Trust Report Excel File";
    for (const f of keys)
        folders[f] = reports[f];
    return folders;
}

export default reports;