import React from 'react';
import Modal from './Modal';

export default class CancelImport extends Modal {
    
    renderContent = () => {
        return (
            <div className="modal-content modal-center">
                <div
                style={{height: 150}}>
                <h1>Cancel Import?</h1>
                <p>Confirm that you want to cancel the import by clicking the <strong>Submit</strong> button. </p>
                
                <br/>
                
                <form
                onSubmit={async(e) => {
                        this.props.loading(9999, "This should just take a moment...", false);
                        e.stopPropagation();
                        e.preventDefault();
                        await this.props.data?.action(this.state.email)
                        setTimeout(() => {
                            this.props.loaded()
                            this.props.modal();
                        }, 2000)
                    }
                }
            >
            <input 
                type='submit'
                value='Submit'
                className='input-inline-submit'
            />
            </form>
            </div>

            </div>
        )
    }

}