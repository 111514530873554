// Developed by Aptus Engineering, Inc. <https://aptusai.com>
// See LICENSE.md file in project root directory

export default (data, type) => {

    const url = window.URL.createObjectURL(new Blob([data], { type }));

    let iframe = document.createElement('iframe');
    document.body.appendChild(iframe);
    iframe.style.display = "none";
    iframe.onload = () => {
        setTimeout(() => {
            iframe.focus();
            iframe.contentWindow.print();
            window.URL.revokeObjectURL(url);
        }, 100);
    }
    iframe.src = url;
}