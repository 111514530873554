import React from 'react';
import Modal from './Modal';

export default class ConfirmEmail extends Modal {
    state = {
		email: ""
    }
    componentDidMount = () => {
        this.setState({email: this.props.user.email})
    }
    setEmailHandler = (e) => {
	    this.setState({email: e.target.value})
    }
    renderContent = () => {
        return (
            <div className="modal-content modal-center">
                <div
                style={{height: 150}}>
                <h1>Confirm your email</h1>
                <p>Confirm the email to which you would like your plan information
                to be sent to, then click the <strong>Submit</strong> button to confirm.</p>
                
                <br/>
                
                <form
                onSubmit={async(e) => {
                        this.props.loading(9999, "This should just take a moment...", false);
                        e.stopPropagation();
                        e.preventDefault();
                        await this.props.data?.action(this.state.email)
                        setTimeout(() => {
                            this.props.loaded()
                            this.props.modal();
                        }, 2000)
                    }
                }
            >
                <input 
                    type='text'
                    id='email'
                    name='email'
                    className={`input-inline-text`}
                    placeholder="Enter your email..."
                    style={{width: 330}}
                    value={this.state.email}
                    onClick={e => e.stopPropagation()}
                    onChange={this.setEmailHandler}
                />
            <input 
                type='submit'
                value='Submit'
                className='input-inline-submit'
            />
            </form>
            </div>

            </div>
        )
    }

}