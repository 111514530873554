import React from 'react';
import Modal from './Modal';
import Select from 'react-select';
import '../../styles/forms.css';

export default class ConfirmEmailYear extends Modal {
    state = {
		email: "",
        year: ""
    }
    editField = (s, f, diff) => {
        let fields = [ ...this.state.sections[s].fields ];
        fields[f] = {
            ...fields[f],
            ...diff
        };
        this.editSection(s, { fields });
    }
    componentDidMount = () => {
        this.setState({email: this.props.user.email})
    }
    setEmailHandler = (e) => {
	    this.setState({email: e.target.value})
    }
    renderContent = () => {
        return (
            <div className="modal-content modal-center">
                <div
                style={{height: 150}}>
                <h1>Select the year for the TA Report and enter your email</h1>
                <br/>
                <p>Please select the year for which you want the Trust Accounting report to be generated.</p>

                <Select
                    className="year-select-container"
                    classNamePrefix="select"
                    options={[{
                        value: "2022",
                        label: "2022"
                    }, {
                        value: "2023",
                        label: "2023"
                    }]}
                    isSearchable={false}
                    placeholder="Select an option..."
                    onChange={(e) => {this.setState({year: e.value})}}
                /> 
                <br/>

                <p>Confirm the email to which you would like your plan information
                to be sent to, then click the <strong>Submit</strong> button to confirm.</p>
                
                <form
                onSubmit={async(e) => {
                        this.props.loading(9999, "This should just take a moment...", false);
                        e.stopPropagation();
                        e.preventDefault();
                        await this.props.data?.action(this.state.email, this.state.year)
                        setTimeout(() => {
                            this.props.loaded()
                            this.props.modal();
                        }, 2000)
                    }
                }
            >
                <input 
                    type='text'
                    id='email'
                    name='email'
                    className={`input-inline-text`}
                    placeholder="Enter your email..."
                    style={{width: 330}}
                    value={this.state.email}
                    onClick={e => e.stopPropagation()}
                    onChange={this.setEmailHandler}
                />

            <input 
                type='submit'
                value='Submit'
                className='input-inline-submit'
            />
            </form>
            </div>
            </div>
        )
    }

}


