import React from 'react';

export default class PageNavigator extends React.Component {

    render = () => {

        const p = this.props;

        return (<>
            <div className='plan-editor-page-navigator-format'>
                <img className='arrow-image' alt='' src="/images/tpaPlan/multiple-previous.svg" onClick={p.firstPage} />
                <img className='arrow-image'  alt='' src="/images/tpaPlan/prev-arrow.svg" onClick={p.prevPage} />
                <div className='plan-editor-page-navigator-bold'>{`${this.props.currentPage * 10 + 1}-${ this.props.newPlanLength ? (this.props.currentPage+1)*10+1 : (this.props.currentPage + 1) * 10 > this.props.planLength ? this.props.planLength : (this.props.currentPage + 1) * 10}`}</div>
                <div> {`of`} </div>
                <div className='plan-editor-page-navigator-bold'>{this.props.planLength}</div>
                <img className='arrow-image'  alt='' src="/images/tpaPlan/next-arrow.svg" onClick={p.nextPage} />
                <img className='arrow-image'  alt='' src="/images/tpaPlan/multiple-next.svg" onClick={p.lastpage}/>
            </div>
        </>)
    }
}