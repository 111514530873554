// Developed by Aptus Engineering, Inc. <https://aptusai.com>
// See LICENSE.md file in project root directory

export default (password) => {

    if (!password)
        return "Password cannot be empty.";

    else if (password.length < 8)
        return "Password must have at least 8 characters.";

    else if (!(new RegExp("^(?=.*[a-z])")).test(password))
        return "Must have atleast 1 lowercase letter.";

    else if (!(new RegExp("^(?=.*[A-Z])")).test(password))
        return "Must have atleast 1 uppercase letter.";

    else if (!(new RegExp("^(?=.*[0-9])")).test(password))
        return "Must have atleast 1 number.";

    else return "ok"; // Finally

}