// Developed by Aptus Engineering, Inc. <https://aptusai.com>
// See LICENSE.md file in project root directory

import { getCardType } from './formatDisplay';

export const isValidPaymentMethod = (card, zip, country) => {

    const ok = {
        card: {
            number: ![undefined, null].includes(getCardType(card.number)),
            expiry: card.expiry.length === 7 && card.expiry[2] === "/" && !isNaN(card.expiry.substr(0,2)) && !isNaN(card.expiry.substr(3)),
            cvc: card.cvc.length >= 3 && !isNaN(card.cvc),
            zip: zip.length >= 5,
            country: country.length >= 2 && country.length <= 3
        }
    };

    ok.all = ok.card.number && ok.card.expiry && ok.card.cvc && ok.card.zip && ok.card.country;

    return ok;

}