// Developed by Aptus Engineering, Inc. <https://aptusai.com>
// See LICENSE.md file in project root directory

export default (data, name) => {

    let a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display:none";
    a.href = window.URL.createObjectURL(new Blob([data]));
    a.download = name;
    a.click();

    window.URL.revokeObjectURL(a.href);
    document.body.removeChild(a);

}