export default (x, forceNumber=false) => {
    if (x.trim() === "-") return 0;
    
    let v = x.replace(/[^0-9.,]/g, '');
    if (!v) return 0;

    // . vs ,
    // If the 3rd position from the right has a ',' it should actually be a decimal point.
    if (v.length > 3 && v[v.length - 3] === ',')
        v = v.slice(0, -3) + '.' + v.slice(-2);

    // If there are decimal points anywhere other than the 3rd position from the right, remove them.
    if (v.slice(0, -3).includes('.'))
        v = v.slice(0,-3).replace(/\./g, '') + '.' + v.slice(-2);

    // Remove commas
    v = v.replace(/,/g, '');

    var n = parseFloat(v.replace(/[^0-9.]/g, ''));
    if (isNaN(n)) return x;
    if (x.includes('(') || x.includes('-') || x.includes(')'))
        return -n;
    return n;
};