// Developed by Aptus Engineering, Inc. <https://aptusai.com>
// See LICENSE.md file in project root directory

export default (nameString) => {

    if (!nameString.includes(","))
        return;

    const commaIdx = nameString.indexOf(",");

    return nameString.substring(commaIdx+1).trim() + " " + nameString.substring(0,commaIdx).trim();

}