// Developed by Aptus Engineering, Inc. <https://aptusai.com>
// See LICENSE.md file in project root directory

export default (event, keyCallbacks) => {

    Object.keys(keyCallbacks).forEach(key => {

        if (event.key === key)
            return keyCallbacks[key]();

    });

}