import parseCurrency from "./parseCurrency";

const getFieldByKey = (meta, key) => {
    for (const f of meta) {
        if (f.key.trim().toLowerCase() === key.trim().toLowerCase())
            return f;
    }
    return null;
};

export default (meta, key, forceNumber=false) => {
    const results = {}; // Keep track of evaluated results.
    
    const evaluate = (key) => {
        // Get value from metadata
        let value = getFieldByKey(meta, key);
        if (!value) return 0;

        // No expression set? Just return the value
        if (!value.expression)
            return parseCurrency(value.value, forceNumber);

        value = value.expression;

        // Value should not be a fully replaced expression
        try {
            // The field value is an expression. To evaluate it, we need to first replace all fields (wrapped in curly braces) with the actual values
            const matches = value.match(/{(.*?)}/g);
            if (matches)
                for (const match of matches) {
                    const key = match.substring(1, match.length-1);
                    // Replace the values with evaluated results - recursive call...
                    if (!results[key]) results[key] = evaluate(key);
                    value = value.replaceAll(match, results[key]);
                }

            // eslint-disable-next-line
            return eval(value.substring(1));
        }
        catch (error) {
            console.error(error);
            return "🚫 ERROR";
        }
    };

    // Evaluate this field key
    const tmp = evaluate(key);
    
    if (!forceNumber && typeof tmp === "number") {
        return tmp < 0 ? `(${Math.abs(tmp).toLocaleString('en-US', { style: 'currency', currency: 'USD', minFractionDigits: 2, maxFractionDigits: 2 }).substring(1)})` : tmp.toLocaleString('en-US', { style: 'currency', currency: 'USD', minFractionDigits: 2, maxFractionDigits: 2 }).substring(1);
    }
    return tmp;

}