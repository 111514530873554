import React from 'react';
import Button from './Button';
import CreatableSelect from 'react-select/creatable';
import { components } from 'react-select';
import inputKey from '../../../utilities/inputKey';
import plural from '../../../utilities/plural';

import '../../../styles/planeditor.css';
export default class AccountCard extends React.Component {

    renderStaticAccountNumber = (a) => this.props.renderLeft(<span
        className={"plan-editor-account-value" + (a.numDocuments ? " plan-editor-account-value-link" : "") + (a.number?.toLowerCase().includes(this.props.query)? ' QueryMatch-Text': '')}
        title={a.numDocuments ? `View ${a.numDocuments} ${plural('document', a.numDocuments)} for account` : ''}
        onClick={() => {
            if (!a.numDocuments) return;
            window.open('/field/account/' + a.number, '_blank');
        }}
        onDoubleClick={this.handleTextSelect}
    >{a.number}</span>, 240)
    
    handleTextSelect = (event) => {
        const { target } = event;

        // Get the selected range 
        const selection = window.getSelection(); 
        const range = selection.getRangeAt(0); 

        // remove all ranges from the selection 
        selection.removeAllRanges(); 

        // set the selection to the target 
        range.selectNodeContents(target); 

        // set the selected range
        selection.addRange(range); 
    }

    render = () => {
        const account = this.props.account ?? {}; 

        const Option = ({ children, ...props }) => (
            <components.Option {...props}>
                <div 
                    style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}
                    title={children}
                >
                    {children}
                </div>
            </components.Option>
        );

        return (
            <div
                className='plan-editor-account-card'
            >
                <div
                    className='plan-editor-account-provider'
                >
                    {!this.props.isEditing?
                        <span 
                            className={"plan-editor-account-value" + (account.provider?.toLowerCase().includes(this.props.query)? ' QueryMatch-Text': '')} 
                            title={account.provider}
                            onDoubleClick={this.handleTextSelect}
                        >
                            {account.provider}
                        </span>   
                    :
                        <CreatableSelect 
                            className="provider-select-container"
                            classNamePrefix='provider-select'
                            components={{ Option }}
                            styles={{
                                valueContainer: (base) => ({
                                    ...base,
                                    overflowX: 'auto',
                                }),
                            }}
                            value={{
                                value: this.props.editingAccount.provider ?? "",
                                label: this.props.editingAccount.provider ?? ""
                            }}
                            options={this.props.investmentProviders?.map(elem => ({
                                value: elem.DisplayName,
                                label: elem.DisplayName
                            }))}
                            onChange = {(e) => this.props.editingAccountField("provider", e.value)}
                            isSearchable={true}
                            placeholder="Provider..."
                        />
                    }
                </div>

                <div
                    className='plan-editor-account-left'
                > 
                    {!this.props.isEditing?
                        <>
                            {this.renderStaticAccountNumber(account)}
                            {this.props.renderLeft(<span 
                                                    className={"plan-editor-account-value" + (account.payee?.toLowerCase().includes(this.props.query)? ' QueryMatch-Text': '')} 
                                                    title={account.payee}
                                                    onDoubleClick={this.handleTextSelect}
                                                    >
                                                        {account.payee}
                                                    </span>, 200)}
                        </>
                    :
                        <>
                            {this.props.renderLeft(<input
                                className="plan-editor-account-edit"
                                type={"text"}
                                value={this.props.editingAccount.number}
                                onChange={(e) =>this.props.editingAccountField("number",e.target.value) }
                                placeholder={"Account number..."}
                                onKeyDown={(e) => inputKey(e, { "Enter": this.props.onClickSave })}
                            />, 240)}
                            {this.props.renderLeft(<input
                                className="plan-editor-account-edit"
                                type={"text"}
                                value={this.props.editingAccount.payee}
                                onChange={e => this.props.editingAccountField("payee",e.target.value)}
                                placeholder={"Payee name..."}
                                onKeyDown={(e) => inputKey(e, { "Enter": this.props.onClickSave })}
                            />, 200)}
                        </>
                    }
                </div>
                

                <div
                    className='plan-editor-account-meta' 
                    key={account.number}
                >
                     
                    {this.props.renderMeta(<>
                    {
                        this.props.isEditing && this.props.editingAccount?.isSaving?
                            <p>Saving...</p>
                        :<>
                            <Button
                            text={this.props.isEditing?"Save":"Edit"}
                            onClick={this.props.isEditing? this.props.onClickSave : this.props.onClickEdit}
                            className="plan-editor-account-action"
                            style={{
                                backgroundImage: `url(/images/icons/${this.props.isEditing?'confirm':'edit'}.png)`,
                            }}
                        />
                        <Button
                            text={this.props.isEditing?"Cancel":"Delete"}
                            onClick={this.props.isEditing? this.props.onClickCancel : this.props.onClickDelete}
                            className="plan-editor-account-action"
                            style={{
                                color: "var(--color-accent-dark)",
                                backgroundImage: `url(/images/icons/delete.png)`,
                            }}
                        /></>
                    }
                    </>, 200)}
                </div>
                
            </div>
        )
    }

}