// Developed by Aptus Engineering, Inc. <https://aptusai.com>
// See LICENSE.md file in project root directory

export default (string, num) => {

    if (num === 0 || num > 1)
        return string + "s";
    
    else return string;

}